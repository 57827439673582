import MultibancoMethod from './MultibancoMethod/MultibancoMethod.vue'
import CreditCardMethod from './CreditCardPayMethod/CreditCardMethod.vue'
import BankTransferMethod from './BankTransferMethod/BankTransferMethod.vue'
import BoletoMethod from './BoletoMethod/BoletoMethod.vue'

export default {
  name: 'PaymentMethod',
  components: {
    MultibancoMethod,
    CreditCardMethod,
    BankTransferMethod,
    BoletoMethod,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
}
