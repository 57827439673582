import InfoMultibanco from '../../Common/InfoMultibanco/InfoMultibanco.vue'

export default {
  name: 'ConfirmMultibanco',
  components: {
    InfoMultibanco,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      showInvoice: false,
      cant: 1,
      subTotal: 0,
      subTotalIva: 0,
    }
  },
  methods: {
    cancelStep () {
      this.payment.step = 2
    },
    goStepInitial () {
      this.payment.reset()
      this.showInvoice = false
      this.payment.step = 1
    },
    invoicePayment () {
      this.payment.complete = true
      this.showInvoice = true
    },
    calculeSubtotals (amount, cant, iva) {
      this.subTotal = parseFloat(cant) * parseFloat(amount)
      this.subTotalIva = (parseFloat(this.subTotal) * parseFloat(iva)) / 100
    },
  },
  watch: {
    payment: {
      handler: function () {
        this.calculeSubtotals(
          this.payment.invoiceTmp.amount,
          this.cant,
          this.payment.invoiceTmp.iva,
        )
      },
      deep: true,
    },
  },
}
