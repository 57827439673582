import PaymentService from '@/services/payment.service.js'

export default {
  name: 'SelectPaymentMethod',
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loadingBankTransfer: false,
      loadingMultibanco: false,
      loadingBoleto: false,
      loadingCreditCard: false,
    }
  },
  methods: {
    selectPayment (paymentMethod) {
      this.payment.paymentMethodSelectedId = paymentMethod

      if (this.payment.isSelectedBankTransferPaymentMethod()) {
        this.getBankPaymentMethod()
      }

      if (this.payment.isSelectedMultibancoPaymentMethod()) {
        this.getMultibancoPaymentMethod()
      }

      if (this.payment.isSelectedBoletoPaymentMethod()) {
        this.getBoletoPaymentMethod()
      }

      if (this.payment.isSelectedCreditCardPaymentMethod()) {
        this.getCreditCardPaymentMethod()
      }
    },
    getBankPaymentMethod () {
      this.loadingBankTransfer = true
      PaymentService.getBankPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.step = 2
          },
          (error) => {
            PaymentService.errorResponse(
              error.response.status,
              this.$t('Error al intentar añadir saldo'),
              this.$t(error.response.data.code),
            )
          },
        )
        .finally(() => {
          this.loadingBankTransfer = false
        })
    },
    getMultibancoPaymentMethod () {
      this.loadingMultibanco = true
      PaymentService.getMultibancoPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.step = 2
          },
          (error) => {
            PaymentService.errorResponse(
              error.response.status,
              this.$t('Error al intentar añadir saldo'),
              this.$t(error.response.data.code),
            )
          },
        )
        .finally(() => {
          this.loadingMultibanco = false
        })
    },
    getBoletoPaymentMethod () {
      this.loadingBoleto = true
      PaymentService.getBoletoPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.step = 2
          },
          (error) => {
            PaymentService.errorResponse(
              error.response.status,
              this.$t('Error al intentar añadir saldo'),
              this.$t(error.response.data.code),
            )
          },
        )
        .finally(() => {
          this.loadingBoleto = false
        })
    },
    getCreditCardPaymentMethod () {
      this.loadingCreditCard = true
      PaymentService.getCreditCardPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.defaultCreditCardId = response.subscribedCards.length > 0 ? response.subscribedCards[0].id : '0'
            this.payment.step = 2
          },
          (error) => {
            PaymentService.errorResponse(
              error.response.status,
              this.$t('Error al intentar añadir saldo'),
              this.$t(error.response.data.code),
            )
          },
        )
        .finally(() => {
          this.loadingCreditCard = false
        })
    },
  },
}
