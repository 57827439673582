import InfoBoleto from '../../Common/InfoBoleto/InfoBoleto.vue'

export default {
  name: 'ConfirmBoleto',
  components: {
    InfoBoleto,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      cant: 1,
      subTotal: 0,
      subTotalIva: 0,
    }
  },
  methods: {
    cancelStep () {
      this.payment.step = 2
    },
    goStepInitial () {
      this.payment.reset()
      this.payment.step = 1
    },
    calculeSubtotals (amount, cant, iva) {
      this.subTotal = parseFloat(cant) * parseFloat(amount)
      this.subTotalIva = (parseFloat(this.subTotal) * parseFloat(iva)) / 100
    },
  },
  watch: {
    payment: {
      handler: function () {
        this.calculeSubtotals(
          this.payment.invoiceTmp.amount,
          this.cant,
          this.payment.invoiceTmp.iva,
        )
      },
      deep: true,
    },
  },
}
