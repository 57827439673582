import UserProfile from '../UserProfile/UserProfile.vue'

export default {
  name: 'InfoBoleto',
  components: {
    UserProfile,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toogleUserProfile () {
      this.payment.showUserProfile = !this.payment.showUserProfile
    },
  },
}
