import ConfirmBankTransfer from './ConfirmBankTransfer/ConfirmBankTransfer.vue'
import ConfirmMultibanco from './ConfirmMultibanco/ConfirmMultibanco.vue'
import ConfirmBoleto from './ConfirmBoleto/ConfirmBoleto.vue'
import ConfirmCreditCard from './ConfirmCreditCard/ConfirmCreditCard.vue'

export default {
  name: 'ConfirmPayment',
  components: {
    ConfirmBankTransfer,
    ConfirmMultibanco,
    ConfirmBoleto,
    ConfirmCreditCard,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
}
