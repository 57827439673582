import EventBus from '@/util/EventBus'
import PaymentService from '@/services/payment.service.js'

export default {
  name: 'CreditCardDelete',
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    toogle () {
      this.payment.showCreditCardDelete = !this.payment.showCreditCardDelete
    },
    cancel () {
      this.toogle()
    },
    acepted () {
      this.loading = true
      const data = {}
      PaymentService.deletedCreditCard(data, this.payment.deletedCreditcardId)
        .then(
          () => {
            this.getCreditCardPaymentMethod()
          },
          (error) => {
            if (error.response.status === 422) {
              EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir saldo'))
            }
            EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.code))
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    getCreditCardPaymentMethod () {
      PaymentService.getCreditCardPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.defaultCreditCardId = response.subscribedCards.length > 0 ? response.subscribedCards[0].id : '0'
            this.toogle()
          },
          (error) => {
            PaymentService.errorResponse(
              error.response.status,
              this.$t('Error al intentar añadir saldo'),
              this.$t(error.response.data.code),
            )
          },
        )
        .finally(() => {})
    },
  },
}
