import EventBus from '@/util/EventBus'
import PaymentService from '@/services/payment.service.js'
import InfoCreditCard from '../../Common/InfoCreditCard/InfoCreditCard.vue'
import CreditCardDelete from './CreditCardDelete/CreditCardDelete.vue'
import { formatDate } from '@/filters/filters.js'

const VALIDATE_AMOUNT = /^\d+(?:[,|.]\d{0,2})?$/

export default {
  name: 'CreditCardMethod',
  components: {
    InfoCreditCard,
    CreditCardDelete,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      creditCardPayment: '0',
      rememberCreditCard: true,
    }
  },
  watch: {
    payment: {
      handler () {
        this.creditCardPayment = this.payment.defaultCreditCardId
      },
      deep: true,
    },
  },
  computed: {
    isNewCreditCard () {
      return +this.creditCardPayment === 0
    },
    hasSubscribedCards () {
      return this.payment.paymentDetail.subscribedCards?.length > 0
    },
    isNotStripe () {
      return this.payment.paymentsInfo.creditCard.provider_slug !== 'stripe'
    },
  },
  methods: {
    formatDate,
    cancelStep () {
      this.resetForm()
      this.payment.reset()
      this.payment.step = 1
    },
    resetForm () {
      this.$refs.formPayment.reset()
      this.resetVeeValidate()
    },
    resetVeeValidate () {
      this.$refs.observer.reset()
    },
    confirmationPayment () {
      this.confimCreditCardPaymentMethod()
    },
    calculeSubtotals () {
      this.subTotal = parseFloat(this.cant) * parseFloat(this.amount.value)
      this.subTotalIva = (parseFloat(this.subTotal) * parseFloat(this.iva)) / 100
    },
    confirmDeleteCreditCard (cardId) {
      this.payment.deletedCreditcardId = cardId
      this.payment.showCreditCardDelete = true
    },
    getRegex () {
      return VALIDATE_AMOUNT
    },
    validateAmount () {
      const regex = VALIDATE_AMOUNT[this.payment.currency.code]
      return regex.test(this.payment.amount)
    },
    parseAmount (amount) {
      return amount.replace(',', '.')
    },
    async confimCreditCardPaymentMethod () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true

      const data = {
        amount: this.parseAmount(this.payment.amount),
        infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
        subscribed_card: this.creditCardPayment,
        rememberCreditCard: this.rememberCreditCard,
      }

      PaymentService.confimCreditCardPaymentMethod(data)
        .then(
          (response) => {
            this.payment.invoiceTmp = response.invoiceTmp
            this.payment.urlCreditCardPay = response.url
            this.payment.creditCardOptions.subscribed_card = response.subscribed_card
            this.payment.creditCardOptions.rememberCreditCard = response.rememberCreditCard
            this.payment.step = 3
          },
          (error) => {
            if (error.response.status === 422) {
              EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir saldo'))
            } else if (error.response.data.minAmount) {
              const minAmount = this.payment.paymentDetail.minAmountLocal
              EventBus.$emit('showAlert', 'danger', this.$t(
                this.$t(error.response.data.code) + ' ' +
                this.currency(minAmount) + ' ' +
                this.payment.setCurrencyCode()))
            } else if (error.response.data.maxAmount) {
              const maxAmount = this.payment.paymentDetail.maxAmountLocal
              EventBus.$emit('showAlert', 'danger', this.$t(
                this.$t(error.response.data.code) + ' ' +
                this.currency(maxAmount) + ' ' +
                this.payment.setCurrencyCode()))
            }
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    infoImporte () {
      const minAmount = this.payment.paymentDetail.minAmountLocal
      const maxAmount = this.payment.paymentDetail.maxAmountLocal

      return this.$t('Importe mínimo') + ' ' +
        this.currency(minAmount) + ' ' +
        this.payment.setCurrencyCode() + ' ' + this.$t('y máximo') + ' ' +
        this.currency(maxAmount) + ' ' +
        this.payment.setCurrencyCode()
    },
    currency (amount, decimalCount = 2, decimal = ',', thousands = '.') {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? '-' : ''

      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
      const j = (i.length > 3) ? i.length % 3 : 0

      return negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
    },
  },
}
