import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import EventBus from '@/util/EventBus'
import { getParameterByName } from '@/util/GetQueryString.js'
import { Payment } from '@/models/payments/Payment.js'
import PaymentService from '@/services/payment.service.js'
import SelectPaymentMethod from './SelectPaymentMethod/SelectPaymentMethod.vue'
import ConfirmPayment from './ConfirmPayment/ConfirmPayment.vue'
import PaymentMethod from './PaymentMethod/PaymentMethod.vue'

export default {
  name: 'PaymentPage',
  components: {
    HeaderTopDashboard,
    SelectPaymentMethod,
    PaymentMethod,
    ConfirmPayment,
  },
  data () {
    return {
      payment: new Payment(),
    }
  },
  methods: {
    getPaymentMethods () {
      PaymentService.getPaymentMethods()
        .then(
          (response) => {
            this.payment.activeCreditCard = response.activeCreditCard
            this.payment.activeMultibanco = response.activeMultibanco
            this.payment.activeBoleto = response.activeBoleto
            this.payment.activePaypal = response.activePaypal
            this.payment.activePlan = response.activePlan
            this.payment.activeSubscription = response.activeSubscription
            this.payment.activeTransfer = response.activeTransfer
            this.payment.billingProfiles = response.billingProfiles
            this.payment.currency = response.currency
            this.payment.fromPlans = response.fromPlans
            this.payment.paymentsInfo = response.paymentsInfo
            this.payment.step = 1
            this.payment.show = true
            this.getPaymentInvoice()
          },
          () => {},
        )
        .finally(() => {})
    },
    getPaymentInvoice () {
      const token = getParameterByName('token')
      const paymentMethod = getParameterByName('paymentMethod')
      const path = getParameterByName('path')
      if (path) {
        this.$router.push({ path })
      }

      if (token && (parseInt(paymentMethod) === this.payment.paymentMethod.creditCard)) {
        this.getPaymentInvoiceCreditCard(token)
      }
    },
    getPaymentInvoiceCreditCard (token) {
      const data = {
        token: token,
      }

      PaymentService.callbackCreditCardPaymentMethod(data)
        .then(
          (response) => {
            this.payment.invoice = response.invoice
            this.payment.paymentMethodSelectedId = this.payment.paymentMethod.creditCard
            this.payment.complete = true
            this.payment.showInvoice = true
            this.payment.step = 3

            this.getUserBalance()
          },
          (error) => {
            if (error.response.status === 422) {
              EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir saldo'))
            }
            EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.code))
          },
        )
        .finally(() => {})
    },
    async getUserBalance () {
      try {
        await this.$store.dispatch('balance/getBalance')
      } catch (error) {
        console.error(error)
      }
    },
  },
  created () {
    this.getPaymentMethods()
  },
}
