import EventBus from '@/util/EventBus'
import PaymentService from '@/services/payment.service.js'
import InfoMultibanco from '../../Common/InfoMultibanco/InfoMultibanco.vue'

const VALIDATE_AMOUNT = /^\d+(?:[,|.]\d{0,2})?$/

export default {
  name: 'MultibancoMethod',
  components: {
    InfoMultibanco,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    getRegex () {
      return VALIDATE_AMOUNT
    },
    cancelStep () {
      this.resetForm()
      this.payment.reset()
      this.payment.step = 1
    },
    resetForm () {
      this.$refs.formPayment.reset()
      this.resetVeeValidate()
    },
    resetVeeValidate () {
      this.$refs.observer.reset()
    },
    confirmationPayment () {
      this.confimMultibancoPaymentMethod()
    },
    async confimMultibancoPaymentMethod () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true

      const data = {
        amount: this.payment.amount,
        infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
      }

      PaymentService.confimMultibancoPaymentMethod(data)
        .then(
          (response) => {
            this.payment.entity = response.entity
            this.payment.reference = response.reference
            this.payment.invoiceTmp = response.invoiceTmp
            this.payment.step = 3
          },
          (error) => {
            if (error.response.status === 422) {
              EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir saldo'))
            } else if (error.response.data.minAmount) {
              const minAmount = this.payment.paymentDetail.minAmountLocal
              EventBus.$emit('showAlert', 'danger', this.$t(
                this.$t(error.response.data.code) + ' ' +
                this.currency(minAmount) + ' ' +
                this.payment.setCurrencyCode()))
            } else {
              const maxAmount = this.payment.paymentDetail.maxAmountLocal
              EventBus.$emit('showAlert', 'danger', this.$t(
                this.$t(error.response.data.code) + ' ' +
                this.currency(maxAmount) + ' ' +
                this.payment.setCurrencyCode()))
            }
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    infoImporte () {
      const minAmount = this.payment.paymentDetail.minAmountLocal
      const maxAmount = this.payment.paymentDetail.maxAmountLocal

      return this.$t('Importe mínimo') + ' ' +
        this.currency(minAmount) + ' ' +
        this.payment.setCurrencyCode() + ' ' + this.$t('y máximo') + ' ' +
        this.currency(maxAmount) + ' ' +
        this.payment.setCurrencyCode()
    },
    currency (amount, decimalCount = 2, decimal = ',', thousands = '.') {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? '-' : ''

      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
      const j = (i.length > 3) ? i.length % 3 : 0

      return negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
    },
  },
}
